.v-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
}

.v-container>div {
  flex: 1;
}

.v-left>.image-container {
  border: 8px solid rgba(232 232 232 /93%);
}

.v-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.v-right>span {
  font-size: 2rem;
  font-weight: 700;
  color: var(--dark-blue-color);
  margin-bottom: 1rem;
}

.v-right>p {
  font-size: 0.9rem;
  color: var(--grey-color);
  margin-bottom: 1.5rem;
}

.accord-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.accord-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 10px;
  border: 0.8px solid var(--border-color);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: var(--box-shadow);
}

.accord-container>.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.acc-icon, .acc-arrow {
  padding: 10px;
  background-color: #eeeeff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.acc-arrow {
  cursor: pointer;
}

.acc-icon>svg {
  fill: #2e4dcf;
}

.acc-title {
  font-size: 1.1rem;
  color: var(--dark-blue-color);
  font-weight: 700;
}

@media (max-width: 1024px) {
  .v-container {
    flex-direction: column;
  }
  .v-right {
    align-items: center;
  }
  @media (max-width: 768px) {
    .acc-title {
      font-size: 0.8rem;
    }
    .acc-icon, .acc-arrow {
      padding: 0.4rem;
    }
    .v-right>.orange-text {
      font-size: 1.2rem;
    }
    .v-right>span {
      font-size: 1.5rem;
    }
    .v-right>p,.acc-des {
      font-size: 0.9rem;
    }
  }
}