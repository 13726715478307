.r-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  position: relative;
}

.r-header > :nth-child(2) {
  font-size: 2rem;
  font-weight: 700;
  color: var(--dark-blue-color);
}

.r-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 10px;
  width: fit-content;
  transition: all 0.3s ease-in;
  flex-direction: column;
}

.r-info:hover {
  scale: 1.025;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(136, 160, 255, 0.46) 217%
  );
  box-shadow: var(--box-shadow);
}

.r-info > img {
  width: 100%;
  max-width: 15rem;
  margin-bottom: 1rem;
}

.r-swiper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 0.6rem;
  padding: 1rem;
}

.r-price {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--grey-color);
}

.r-price > :first-child {
  color: var(--orange-color);
}

.r-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--dark-blue-color);
}

.r-detail {
  font-size: 0.7rem;
  color: var(--grey-color);
}

.r-button {
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-button>button {
  border: none;
  background-color: var(--light-blue-color);
  width: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.r-button>button:hover {
  box-shadow: var(--box-shadow);
}

@media (max-width: 640px) {
  .r-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .r-header>.orange-text {
    font-size: 1.2rem;
  }
  .r-header>:last-child {
    font-size: 1.5rem;
  }
  .r-info {
    align-items: center;
  }
}