.c-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem;
}

.c-container>img {
  width: 9rem;
}
