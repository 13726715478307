.s-wrapper {
  padding: 2rem;
}

.s-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
  flex-direction: column;
  background: #4161df;
  border-radius: 10px;
  color: var(--white-color);
}

.s-container>h2 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.s-container>p {
  font-size: 0.9rem;
  color: #ffffff96;
  text-align: center;
  margin-bottom: 1.2rem;
}

.s-container>.btn {
  border: 2px solid white;
  background-color: #5a73d7;
  border-radius: 10px;
}

@media (max-width: 640px) {
 .s-container>h2 {
  font-size: 1rem;
 } 
 .s-container>.btn {
  font-size: 0.6rem;
  padding: 0.7rem 1rem;
 }
}