.App {
  position: relative;
  overflow-x: clip;
}

.App>:first-child {
  position: relative;
  background-color: var(--black-color);
}

.white-gradient {
  width: 20rem;
  height: 20rem;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.522);
  border-radius: 100px;
  filter: blur(100px);
}